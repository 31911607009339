import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DesktopSide from './desktop/DesktopSide';
import MobileSide from './mobile/MobileSide';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { useProfileUserQuery } from '../../../../store/redux/user/hooks/useUsersQuery';
import { sendGetRecordsRequest, startMessagesListening, stopMessagesListening } from '../../../../store/redux/websocket/chat-reducer';
import { getCookie } from '../../../../utils/cookies';

const Sidebar: React.FC = () => {
  const { data: activeFilial, isLoading: filialLoading } = useFilialQuery();
  const { data: profileUser, isLoading: userLoading } = useProfileUserQuery(getCookie('id'));
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const [isDesktop] = useMatchMedia(['((min-width: 901px))']);

  useEffect(() => {
    setUserRole(profileUser?.role);
  }, [userLoading]);

  useEffect(() => {
    dispatch(startMessagesListening(profileUser?.fio) as any);

    if (profileUser?.role) {
      dispatch(
        sendGetRecordsRequest({ filialId: activeFilial?.id || '', filialName: activeFilial?.name || '', userRole: userRole || '' }) as any
      );
    }

    return () => {
      dispatch(stopMessagesListening() as any);
    };
  }, [userLoading, filialLoading]);

  return (
    <>
      {isDesktop && <DesktopSide />}
      {isMobile && <MobileSide />}
    </>
  );
};

export default Sidebar;
