import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { BreakLabel, CloseBtn, DurationWrap, Wrapper } from './CreateWeekTimeTable.styled';

import { ModalGrid } from '../../../../../pages/RecordingPage/lib/CommonRecording.styled';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import Button from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import Timepicker from '../../../../../shared/new-ui/NewTimePicker/ui/NewTimepicker';
import ToggleButton from '../../../../../shared/new-ui/NewToggleButton/ui/NewToggleButton';
import { InputType, Text, TextType } from '../../../../../shared/ui';
import { useUpdateFilial } from '../../../../../store/redux/filial/hooks/useFilialMutations';
import { useAllUsersScheduleQuery, useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { IBreak } from '../../../../../store/redux/user/user.interface';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { apiPut } from '../../../../../utils/apiInstance';
import { generateRandomString, getTimeDurationStr } from '../../../../../utils/helperFunctions';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';
import { IconNew, EIcon } from '../../../../icons/medium-new-icons/icon';
import { TimeworkValues } from '../../update/filial/EditFilialTimeModal';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
  closeOnOverlayClick?: boolean;
}

interface FormValues {
  [key: string]: string | IBreak[];
}
type DataItem = {
  label: string;
  value: string;
};

const days: DataItem[] = [
  { label: 'Понедельник', value: 'monday' },
  { label: 'Вторник', value: 'tuesday' },
  { label: 'Среда', value: 'wednesday' },
  { label: 'Четверг', value: 'thursday' },
  { label: 'Пятница', value: 'friday' },
  { label: 'Суббота', value: 'saturday' },
  { label: 'Воскресенье', value: 'sunday' }
];

const CreateWeekTimeTable: FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { setActiveDate, clearTimeTableArr } = useActions();
  const { chosenWeek } = useTypedSelector((state1) => state1.calendar);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { showModal } = useTypedSelector((state) => state.modal);
  const { data: user } = useUsersQuery();
  const { data: userSchedules, refetch: updateTimeTable } = useAllUsersScheduleQuery({
    ids: user?.map((item) => item.id),
    dateStart: chosenWeek[0],
    dateEnd: chosenWeek[chosenWeek.length - 1]
  });
  const { mutateAsync: updateFilial } = useUpdateFilial();

  const chosenSchedules = userSchedules?.filter((schedule) => schedule.employeeId === modalPayload?.employeeId && !schedule.description);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [pending, setPending] = useState(false);
  const [isNonWorkingMonday, setIsNonWorkingMonday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 1 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['monday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingTuesday, setIsNonWorkingTuesday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 2 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['tuesday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingWednesday, setIsNonWorkingWednesday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 3 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['wednesday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingThursday, setIsNonWorkingThursday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 4 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['thursday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingFriday, setIsNonWorkingFriday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 5 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['friday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingSaturday, setIsNonWorkingSaturday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 6 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['saturday'].timestart !== ''
      ? false
      : true
  );
  const [isNonWorkingSunday, setIsNonWorkingSunday] = useState<boolean>(
    chosenSchedules?.find((item) => dayjs(item.date).day() === 0 && item.timestart !== '')
      ? false
      : modalPayload && modalPayload.filialWork && modalPayload.filialWork['sunday'].timestart !== ''
      ? false
      : true
  );
  const [breaksNumArr, setBreaksNumArr] = useState<number[]>([]);

  const initialValues: FormValues = {
    timestart0: '',
    timeend0: '',
    timestart1: '',
    timeend1: '',
    timestart2: '',
    timeend2: '',
    timestart3: '',
    timeend3: '',
    timestart4: '',
    timeend4: '',
    timestart5: '',
    timeend5: '',
    timestart6: '',
    timeend6: '',
    breaks0: [{ bstart: '', bend: '' }],
    breaks1: [{ bstart: '', bend: '' }],
    breaks2: [{ bstart: '', bend: '' }],
    breaks3: [{ bstart: '', bend: '' }],
    breaks4: [{ bstart: '', bend: '' }],
    breaks5: [{ bstart: '', bend: '' }],
    breaks6: [{ bstart: '', bend: '' }]
  };
  const formik = useFormik<FormValues>({
    initialValues:
      modalPayload && modalPayload.employeeId
        ? {
            timestart0: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 1)?.timestart || '' : '',
            timeend0: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 1)?.timeend || '' : '',
            timestart1: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 2)?.timestart || '' : '',
            timeend1: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 2)?.timeend || '' : '',
            timestart2: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 3)?.timestart || '' : '',
            timeend2: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 3)?.timeend || '' : '',
            timestart3: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 4)?.timestart || '' : '',
            timeend3: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 4)?.timeend || '' : '',
            timestart4: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 5)?.timestart || '' : '',
            timeend4: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 5)?.timeend || '' : '',
            timestart5: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 6)?.timestart || '' : '',
            timeend5: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 6)?.timeend || '' : '',
            timestart6: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 0)?.timestart || '' : '',
            timeend6: chosenSchedules ? chosenSchedules?.find((item) => dayjs(item.date).day() === 0)?.timeend || '' : '',
            breaks0: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 1)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks1: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 2)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks2: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 3)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks3: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 4)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks4: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 5)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks5: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 6)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }],
            breaks6: chosenSchedules
              ? chosenSchedules?.find((item) => dayjs(item.date).day() === 0)?.breaks || [{ bstart: '', bend: '' }]
              : [{ bstart: '', bend: '' }]
          }
        : modalPayload && modalPayload.filialWork
        ? {
            timestart0: modalPayload.filialWork ? modalPayload.filialWork['monday'].timestart : '',
            timeend0: modalPayload.filialWork ? modalPayload.filialWork['monday'].timeend : '',
            timestart1: modalPayload.filialWork ? modalPayload.filialWork['tuesday'].timestart : '',
            timeend1: modalPayload.filialWork ? modalPayload.filialWork['tuesday'].timeend : '',
            timestart2: modalPayload.filialWork ? modalPayload.filialWork['wednesday'].timestart : '',
            timeend2: modalPayload.filialWork ? modalPayload.filialWork['wednesday'].timeend : '',
            timestart3: modalPayload.filialWork ? modalPayload.filialWork['thursday'].timestart : '',
            timeend3: modalPayload.filialWork ? modalPayload.filialWork['thursday'].timeend : '',
            timestart4: modalPayload.filialWork ? modalPayload.filialWork['friday'].timestart : '',
            timeend4: modalPayload.filialWork ? modalPayload.filialWork['friday'].timeend : '',
            timestart5: modalPayload.filialWork ? modalPayload.filialWork['saturday'].timestart : '',
            timeend5: modalPayload.filialWork ? modalPayload.filialWork['saturday'].timeend : '',
            timestart6: modalPayload.filialWork ? modalPayload.filialWork['sunday'].timestart : '',
            timeend6: modalPayload.filialWork ? modalPayload.filialWork['sunday'].timeend : ''
          }
        : initialValues,
    onSubmit: (values: any) => {}
  });
  const updateEmployeeSchedule = async (values: any) => {
    setPending(true);
    if (chosenSchedules && modalPayload && modalPayload.employeeId) {
      const resultArr = chosenWeek.map((day, i: number) => {
        return {
          date: dayjs(day).format('YYYY-MM-DD'),
          timestart: !handleChooseIsNonWorking(i) ? '' : values[`timestart${i}`],
          timeend: !handleChooseIsNonWorking(i) ? '' : values[`timeend${i}`],
          breaks: !handleChooseIsNonWorking(i)
            ? null
            : values[`breaks${i}`] && values[`breaks${i}`].filter((item: any) => item.bstart !== '' && item.bend !== '').length > 0
            ? values[`breaks${i}`].filter((item: any) => item.bstart !== '' && item.bend !== '')
            : null
        };
      });
      const res = await apiPut(`/scheduser/${modalPayload.employeeId}`, resultArr);
      if (res.status === 200) {
        setPending(false);
        toast.success('График обновлен');
        closeModal!();
        setActiveDate(chosenWeek[0]);
        updateTimeTable();
      } else {
        setPending(false);
        toast.error(res?.data.description || 'Ошибка');
      }

      clearTimeTableArr();
    } else {
      toast('Выберите хотя бы 1 день');
    }
  };
  const updateFilialSchedule = async () => {
    setPending(true);
    if (!modalPayload || !modalPayload.activeFilial) return;
    const resObj = days.reduce((acc, item: DataItem, index: number) => {
      acc[item.value] = {
        timestart: !handleChooseIsNonWorking(index)
          ? ''
          : formik.values[`timestart${index}`] !== ''
          ? (formik.values[`timestart${index}`] as string)
          : '',
        timeend: !handleChooseIsNonWorking(index)
          ? ''
          : formik.values[`timeend${index}`] !== ''
          ? (formik.values[`timeend${index}`] as string)
          : ''
      };

      return acc;
    }, {} as { [key: string]: TimeworkValues });
    const res = await updateFilial({
      id: modalPayload.filialId,
      address: modalPayload.activeFilial.address,
      name: modalPayload.activeFilial.name,
      phone: modalPayload.activeFilial.phone,
      rucovoditel: modalPayload.activeFilial.rucovoditel,
      yurlico: modalPayload.activeFilial.yurlico,
      timework: [resObj]
    });
    if (res.status === 'ok') {
      setPending(false);
      toast.success('График обновлен');
      closeModal!();
    } else {
      setPending(false);
      toast.error(res?.description || 'Ошибка');
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (modalPayload && modalPayload.filialWork) {
      updateFilialSchedule();
    } else {
      updateEmployeeSchedule(formik.values);
    }
  };

  const addBreakInput = (day: number) => {
    const currBreakField = `breaks${day}`;
    if (formik.values && (formik.values[currBreakField] as IBreak[]).length >= 2) return;
    setBreaksNumArr((prevState) => (prevState.indexOf(day) !== -1 ? [...prevState] : [...prevState, day]));
    const currBreaks = formik.values[currBreakField] as IBreak[];
    if (currBreaks.length === 1 && currBreaks[0].bstart === '' && breaksNumArr.indexOf(day) === -1) {
      formik.setFieldValue(currBreakField, currBreaks);
    } else {
      formik.setFieldValue(currBreakField, [...currBreaks, { bstart: '', bend: '' }]);
    }
  };
  const handleInputChange = (dayIndex: number, breakIndex: number, e: ChangeEvent<HTMLInputElement>, name: string) => {
    const currBreakField = `breaks${dayIndex}`;
    const currBreaks = formik.values[currBreakField] as IBreak[];
    const newInputs = [...currBreaks];

    if (name === 'bstart') {
      newInputs[breakIndex] = { ...newInputs[breakIndex], bstart: e.target?.value };
    } else if (name === 'bend') {
      newInputs[breakIndex] = { ...newInputs[breakIndex], bend: e.target?.value };
    }
    formik.setFieldValue(currBreakField, newInputs);
  };

  const deleteBreakInput = (dayIndex: number, index: number) => {
    const currBreakField = `breaks${dayIndex}`;
    const currBreaks = formik.values[currBreakField] as IBreak[];
    const filterBreaks = currBreaks.filter((item: any, i: number) => index !== i);
    formik.setFieldValue(currBreakField, [...filterBreaks]);
    if (filterBreaks.length === 0) {
      setBreaksNumArr((prevState) => [...prevState.filter((item) => item !== dayIndex)]);
    }
  };

  const handleIsNonWorking = (weekDay: number) => {
    switch (weekDay) {
      case 6:
        setIsNonWorkingSunday(!isNonWorkingSunday);

        return;
      case 0:
        setIsNonWorkingMonday(!isNonWorkingMonday);

        return;
      case 1:
        setIsNonWorkingTuesday(!isNonWorkingTuesday);

        return;
      case 2:
        setIsNonWorkingWednesday(!isNonWorkingWednesday);

        return;
      case 3:
        setIsNonWorkingThursday(!isNonWorkingThursday);

        return;
      case 4:
        setIsNonWorkingFriday(!isNonWorkingFriday);

        return;
      case 5:
        setIsNonWorkingSaturday(!isNonWorkingSaturday);

        return;
    }
  };
  const handleChooseIsNonWorking = (weekDay: number) => {
    switch (weekDay) {
      case 6:
        return !isNonWorkingSunday;
      case 0:
        return !isNonWorkingMonday;
      case 1:
        return !isNonWorkingTuesday;
      case 2:
        return !isNonWorkingWednesday;
      case 3:
        return !isNonWorkingThursday;
      case 4:
        return !isNonWorkingFriday;
      case 5:
        return !isNonWorkingSaturday;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!pathname.includes('/timetable') && showModal) {
      closeModal!();
    }
  }, [pathname]);

  return (
    <Wrapper>
      <FlexWithAlign
        $gap={MODAL_GAP}
        $column
      >
        <FlexWithAlign
          className='top-wrapper'
          $gap={'12px'}
          $align={'center'}
        >
          <CloseBtn
            className='flex'
            onClick={() => {
              clearTimeTableArr();
              closeModal();
            }}
          >
            <IconNew name={EIcon.close} />
          </CloseBtn>
          <Text
            $tag={TextType.H5}
            fontWeight={700}
            className='title'
          >
            {t('Редактирование • ')}
          </Text>
          <NewText
            $customSize={TextCustomType.T28}
            fontWeight={700}
          >
            {t('Режим работы')}
          </NewText>
        </FlexWithAlign>
        <FlexWithAlign
          $column
          $gap={MODAL_TEXT_GAP}
          className={'form-wrapper'}
        >
          <FormStyle onSubmit={handleSubmit}>
            <FlexWithAlign
              $column
              $gap='20px'
            >
              {chosenWeek &&
                chosenWeek.map((day, index: number) => (
                  <FlexWithAlign
                    $column
                    $gap='20px'
                    className='form-content'
                    key={day}
                  >
                    <FlexWithAlign $gap='12px'>
                      <ToggleButton
                        isActive={handleChooseIsNonWorking(index)}
                        onClick={() => handleIsNonWorking(index)}
                        className='is-working-btn'
                      >
                        <span>{dayjs(day).format('dd')}</span>
                      </ToggleButton>
                      <ModalGrid className='input-wrap'>
                        <Timepicker
                          size={InputType.M}
                          // label={t('Начало')}
                          value={formik.values[`timestart${index}`] as string}
                          name={`timestart${index}`}
                          onChange={formik.handleChange}
                          className='time-table-input'
                          disabled={!handleChooseIsNonWorking(index)}
                        />
                        <Timepicker
                          size={InputType.M}
                          // label={t('Конец')}
                          value={formik.values[`timeend${index}`] as string}
                          name={`timeend${index}`}
                          onChange={formik.handleChange}
                          lowerLimit={formik.values[`timestart${index}`] as string}
                          className='time-table-input'
                          disabled={!handleChooseIsNonWorking(index)}
                        />
                        <DurationWrap
                          $gap='4px'
                          $align='center'
                        >
                          <IconNew name={EIcon.change} />
                          {formik.values[`timestart${index}`] && formik.values[`timeend${index}`] && (
                            <span>
                              {`${getTimeDurationStr(
                                formik.values[`timestart${index}`] as string,
                                formik.values[`timeend${index}`] as string,
                                true
                              )}`}
                            </span>
                          )}
                        </DurationWrap>
                      </ModalGrid>
                      {modalPayload && modalPayload.filialWork ? null : (
                        <Button
                          typeBtn='without-border'
                          onClick={() => addBreakInput(index)}
                          disabled={!handleChooseIsNonWorking(index)}
                          classNameWrapper={'add-break-btn'}
                        >
                          <IconNew name={EIcon.addbreak} />
                          <span>{t('Добавить перерыв')}</span>
                        </Button>
                      )}
                    </FlexWithAlign>

                    {breaksNumArr.indexOf(index) !== -1 ||
                    (chosenSchedules &&
                      chosenSchedules.filter((item) => dayjs(item.date).day() === dayjs(day).day())[0]?.breaks &&
                      (formik.values[`breaks${index}`] as IBreak[]) &&
                      formik.values[`breaks${index}`].length > 0) ? (
                      <>
                        <FlexWithAlign
                          $gap='12px'
                          $column
                        >
                          {(formik.values[`breaks${index}`] as IBreak[]).map((input: any, i: number) => (
                            <FlexWithAlign
                              $gap='12px'
                              key={generateRandomString()}
                              $align='center'
                            >
                              <BreakLabel className='break-label'>{t('Перерыв')}</BreakLabel>
                              <ModalGrid className='break-wrap'>
                                <Timepicker
                                  size={InputType.M}
                                  // label={t('Начало')}
                                  value={
                                    (formik.values[`breaks${index}`] as IBreak[]).find((item: any, itemIndex: number) => itemIndex === i)
                                      ?.bstart || input.bstart
                                  }
                                  name={`bstart-${index}-${i}`}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, i, e, 'bstart')}
                                  lowerLimit={formik.values[`timestart${index}`]}
                                  upperLimit={input.bend || formik.values[`timeend${index}`]}
                                  disabled={!formik.values[`timeend${index}`] || !formik.values[`timestart${index}`]}
                                  isBreak={true}
                                  className='time-table-input'
                                />
                                <Timepicker
                                  size={InputType.M}
                                  // label={t('Конец')}
                                  value={
                                    (formik.values[`breaks${index}`] as IBreak[]).find((item: any, itemIndex: number) => itemIndex === i)
                                      ?.bend || input.bend
                                  }
                                  name={`bend-${index}-${i}`}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, i, e, 'bend')}
                                  lowerLimit={input.bstart || formik.values[`timestart${index}`]}
                                  upperLimit={formik.values[`timeend${index}`]}
                                  disabled={!formik.values[`timeend${index}`] || !formik.values[`timestart${index}`]}
                                  isBreak={true}
                                  className='time-table-input'
                                />
                                <FlexWithAlign
                                  $gap='2px'
                                  $align='center'
                                >
                                  <DurationWrap
                                    $gap='4px'
                                    $align='center'
                                  >
                                    <IconNew name={EIcon.change} />
                                    {(formik.values[`breaks${index}`] as IBreak[]) &&
                                      (formik.values[`breaks${index}`] as IBreak[]).find((item: any, itemIndex: number) => itemIndex === i)
                                        ?.bstart &&
                                      (formik.values[`breaks${index}`] as IBreak[]).find((item: any, itemIndex: number) => itemIndex === i)
                                        ?.bend && (
                                        <span>
                                          {`${getTimeDurationStr(
                                            (formik.values[`breaks${index}`] as IBreak[]).find(
                                              (item: any, itemIndex: number) => itemIndex === i
                                            )?.bstart,
                                            (formik.values[`breaks${index}`] as IBreak[]).find(
                                              (item: any, itemIndex: number) => itemIndex === i
                                            )?.bend,
                                            true
                                          )}`}
                                        </span>
                                      )}
                                  </DurationWrap>
                                  <button
                                    className='delete-break-btn'
                                    onClick={() => deleteBreakInput(index, i)}
                                  >
                                    <IconNew name={EIcon.deletebreak} />
                                  </button>
                                </FlexWithAlign>
                              </ModalGrid>
                            </FlexWithAlign>
                          ))}
                        </FlexWithAlign>
                      </>
                    ) : null}
                  </FlexWithAlign>
                ))}
            </FlexWithAlign>

            <FlexWithAlign
              $justify='flex-end'
              $gap='16px'
              className='btns-wrap'
            >
              <Button
                onClick={() => {
                  clearTimeTableArr();
                  closeModal();
                }}
              >
                <span>{t('Отмена')}</span>
              </Button>
              <Button
                type='submit'
                disabled={!modalPayload}
                typeBtn='black'
              >
                <span>{t('Сохранить')}</span>
              </Button>
            </FlexWithAlign>
          </FormStyle>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default CreateWeekTimeTable;
