import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { INewCategoriesList } from './NewCategoriesList.props';
import { ArrowWrap, CategoriesWrapper, CountLabel, HeadItem, Item, List } from './NewCategoriesList.styled';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { cn } from '../../../lib';
import { useTypedSelector } from '../../../lib/hooks/useTypedSelector';
import NewText from '../../NewText/ui/NewText';

const NewCategoriesList: FC<INewCategoriesList> = ({ items, mainLabel, onClick }) => {
  const { t } = useTranslation();
  const { activeProduct } = useTypedSelector((state) => state.products);

  return (
    <CategoriesWrapper>
      {items && items.length > 0 ? (
        <List>
          <HeadItem
            onClick={() => onClick({ value: 'all', title: mainLabel })}
            className={cn(activeProduct.value === 'all' && 'active')}
          >
            <ArrowWrap>
              <IconNew name={EIcon.chevronupnew} />
            </ArrowWrap>
            <FlexWithAlign
              $gap='8px'
              $align='center'
            >
              <NewText color='title'>{t(mainLabel)}</NewText>
              {items.reduce((acc, val) => {
                return acc + (val?.count || 0);
              }, 0) !== 0 ? (
                <CountLabel>
                  {items.reduce((acc, val) => {
                    return acc + (val?.count || 0);
                  }, 0)}
                </CountLabel>
              ) : null}
            </FlexWithAlign>
          </HeadItem>
          {items.map((item) => (
            <Item
              key={item.value}
              onClick={() => onClick({ value: item.value, title: item.title })}
              className={cn(activeProduct.value === item.value && 'active')}
            >
              <NewText>{t(item.title)}</NewText>
              <CountLabel>{item.count}</CountLabel>
            </Item>
          ))}
        </List>
      ) : null}
    </CategoriesWrapper>
  );
};

export default NewCategoriesList;
