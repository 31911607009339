import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useActions } from '../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { Text, TextPType, TextType, TopTabs } from '../../../../shared/ui';
import AnimatedPage from '../../../../shared/ui/AnimatedPage/AnimatedPage';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { sendGetRecordsRequest, startMessagesListening, stopMessagesListening } from '../../../../store/redux/websocket/chat-reducer';
import { getCookie } from '../../../../utils/cookies';
import { LoadingAnimation } from '../content/LoadingAnimation';
import { MobileMessengerWrapper, Notify } from '../content/MessengerContent.styled';
import MessengerOptions from '../side/options/MessengerOptions';

const MessengerList: FC = () => {
  const { t } = useTranslation();
  const { data: activeFilial } = useFilialQuery();
  const { fetchUserByIdAsync } = useActions();
  const { status } = useTypedSelector((state) => state.chat);
  const { profileUser, state: userState } = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  useEffect(() => {
    fetchUserByIdAsync(getCookie('id'));
  }, []);
  const userRole = profileUser.role;
  useEffect(() => {
    dispatch(startMessagesListening(profileUser?.fio) as any);

    if (profileUser.role) {
      dispatch(sendGetRecordsRequest({ filialId: activeFilial?.id || '', filialName: activeFilial?.name || '', userRole }) as any);
    }

    return () => {
      dispatch(stopMessagesListening() as any);
    };
  }, [userState]);

  return (
    <>
      {status === 'pending' && (
        <Notify $align={'center'}>
          <LoadingAnimation />
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P15}
          >
            Загрузка данных
          </Text>
        </Notify>
      )}
      {status === 'error' && (
        <Notify $align={'center'}>
          <LoadingAnimation />
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P15}
          >
            Восстановление соединения
          </Text>
        </Notify>
      )}
      <AnimatedPage>
        {isMobile && (
          <TopTabs title={t('Чат')}>
            <MessengerOptions />
          </TopTabs>
        )}
        <MobileMessengerWrapper>{/* <MessengerSide /> */}</MobileMessengerWrapper>
      </AnimatedPage>
    </>
  );
};

export default MessengerList;
