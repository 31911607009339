import dayjs from 'dayjs';

import { IFilialTimeWork } from '../../../../store/redux/filial/filial.interface';
import {
  IUser,
  IUserAllScheduleQueryData,
  IUserChangePassword,
  IUserInput,
  IUserSchedule,
  IUserScheduleQueryData,
  IUserScheduleTemplate
} from '../../../../store/redux/user/user.interface';
import { apiGet, apiPost, apiPut, apiDelete } from '../../interceptors';

export const UserService = {
  async fetchUserById(id: string | undefined) {
    const response = await apiGet(`/user/${id}`);

    return response.data;
  },
  async fetchUsers() {
    const response = await apiGet(`/users`);

    return response.data;
  },

  async fetchEmployees() {
    const response = await apiGet(`/users`);
    const users = response.data.users || [];
    // const employees = users.filter((user: IUser) => user.role !== 'admin');
    const employees = users;

    return { users, employees };
  },

  async updateUser(data: IUser) {
    const response = await apiPost(`/user/${data.id}`, { ...data });

    return response.data;
  },

  async changePassword(data: IUserChangePassword) {
    await apiPost('/users/changepassword', data);
  },

  async updateUserSchedule(id: string, data: IUserSchedule[]) {
    const formattedData = data.map((item) => ({
      date: dayjs(item.date).format('YYYY-MM-DD'),
      timestart: item.timestart,
      timeend: item.timeend
    }));
    const response = await apiPut(`/scheduser/${id}`, formattedData);

    return response.data;
  },

  async createEmployeer(data: IUserInput) {
    const response = await apiPut(`/user`, {
      ...data
    });

    return response.data;
  },

  async deleteEmployeer(id: string) {
    const response = await apiDelete(`/user/${id}`);

    return response.data;
  },

  async fetchUserScheduleForPeriod(data: IUserScheduleQueryData) {
    const { id, dateStart, dateEnd } = data;
    const response = await apiPost(`/scheduser/${id}`, {
      date: dayjs(dateStart).format('YYYY-MM-DD'),
      dateend: dayjs(dateEnd).format('YYYY-MM-DD')
    });

    return response.data;
  },

  async updateUserScheduleWeeks(id: string | undefined, data: IFilialTimeWork | IUserScheduleTemplate, weeks: number) {
    if (!id) return;
    const response = await apiPut(`/scheduser_template/${id}`, { data: { ...data, weeks: weeks } });

    return response;
  },
  async updateUserScheduleTemplate(id: string | undefined, data: IUserScheduleTemplate) {
    if (!id) return;
    const { weeks, ...otherData } = data;
    const response = await apiPut(`/scheduser_template/${id}`, { ...otherData, weeks: weeks });

    return response;
  },
  async fetchUserSchedule(data: IUserAllScheduleQueryData) {
    const { ids, dateStart, dateEnd } = data;
    let resultArr: any[] = [];
    if ((ids && ids.length === 0) || !ids) return resultArr;

    const promises = ids.map((id) =>
      apiPost(`/scheduser/${id}`, {
        date: dayjs(dateStart).format('YYYY-MM-DD'),
        dateend: dayjs(dateEnd).format('YYYY-MM-DD')
      })
    );

    const values = await Promise.all(promises);
    if (values.length > 0) {
      resultArr = values.map((value) =>
        Array.isArray(value.data)
          ? value.data.map((item: any) => ({
              ...item,
              employeeId: value.config.url?.split('/scheduser/')[1]
            }))
          : { ...value.data, employeeId: value.config.url?.split('/scheduser/')[1] }
      );
    }

    return resultArr;
  },
  async fetchApiUsers(apikey: string) {
    if (!apikey) return;
    const response = await apiPost('/api/users', {
      apikey: apikey
    });

    return response.data;
  }
};
