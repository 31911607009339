import React from 'react';

import {
  BusinessSettingsPageAsync,
  CashRegisterCreateAsync,
  OnlineRecordingPageAsync,
  PersonalAreaPageAsync,
  ProductsPageAsync,
  ProfilePageAsync,
  SalesPageAsync,
  ServiceCategoriesPageAsync,
  SettingsPageAsync,
  CabinetsPageAsync,
  ConfirmRegistrationAsync,
  CashRegisterAndBillsPageAsync,
  ProductEmployeeAsync,
  ProductCabinetAsync,
  PaymentsAndSalesPageAsync,
  PaymentsSettingsPageAsync,
  TransactionsAllAsync,
  SalesCashRegistersAndBillsAsync,
  SalesTransactionsAsync
} from '../../../../pages';
import { AnalyticsViewPageAsync } from '../../../../pages/AnalyticsPage';
import { AuthPageAsync } from '../../../../pages/AuthPage';
import { BusinessSettingsCategoryAsync } from '../../../../pages/BusinessSettingsPage/ui/libs/category';
import { BusinessSettingsLogoAsync } from '../../../../pages/BusinessSettingsPage/ui/libs/logo';
import { BusinessSettingsMainAsync } from '../../../../pages/BusinessSettingsPage/ui/libs/main';
import { BusinessSettingsOperatingModeAsync } from '../../../../pages/BusinessSettingsPage/ui/libs/operating-mode';
import { CabinetsPageAddAsync } from '../../../../pages/CabinetsAddPage/ui/CabinetsAddPage.async';
import { CalendarSettingsPageAsync } from '../../../../pages/CalendarSettingsPage/ui/CalendarSettingsPage.async';
import { CallDialogInIdleTimeAsync, CallProductAsync } from '../../../../pages/CallPage';
import { ClientPageAsync } from '../../../../pages/ClientPage';
import { ClientImportPageAsync } from '../../../../pages/ClientPage/lib/client-import-page';
import { ClientEditPageAsync } from '../../../../pages/ClientPage/ui/edit/ClientEditPage.async';
import { EmployeesCreatePageAsync } from '../../../../pages/EmployeesCreatePage';
import { EmployeersPageAsync, EmployeeTimeTablePageAsync } from '../../../../pages/EmployeesPage';
import { MessengerAsync } from '../../../../pages/MessengerPage';
import MessengerList from '../../../../pages/MessengerPage/ui/mobile/MessengerList';
import MobileChat from '../../../../pages/MessengerPage/ui/mobile/MobileChat';
import { MorePageAsync } from '../../../../pages/MorePage';
import { NotFoundPageAsync } from '../../../../pages/NotFoundPage';
import { PersonalSitePageAsync } from '../../../../pages/OnlineRecordingPage/ui/personal-site/PersonalSitePage.async';
import { TelegramBotInfoPageAsync } from '../../../../pages/OnlineRecordingPage/ui/telegram-bot-desktop/info/TelegramBotInfoPage.async';
import { PersonalAreaSettingsPageAsync } from '../../../../pages/PersonalAreaSettingsPage/PersonalAreaSettingsPage.async';
import { PortalRedirectPaymentPageAsync } from '../../../../pages/PortalSettingsPage';
import AgreementPage from '../../../../pages/PortalTelebonPage/agreement/AgreementPage';
import LicencePage from '../../../../pages/PortalTelebonPage/licence/LicencePage';
import PolicyPage from '../../../../pages/PortalTelebonPage/policy/PolicyPage';
import { PortalTelebonPageAsync } from '../../../../pages/PortalTelebonPage/PortalTelebonPage.async';
import { CreateProductPageAsync } from '../../../../pages/ProductsPage/ui/edit/CreateProductPage.async';
import { ProfileLogoPageAsync } from '../../../../pages/ProfilePage/ui/pages/logo';
import { ProfileSecurityPageAsync } from '../../../../pages/ProfileSecurityPage/ProfileSecurityPage.async';
import { RegistrationPageAsync } from '../../../../pages/RegistrationPage';
import { NewPasswordAsync, ResetPasswordAsync, SuccessPaswordAsync } from '../../../../pages/ResetPasswordPage';
import { SuccesSalePageAsync } from '../../../../pages/SalesPage/ui/success/SuccessSalePage.async';
import { ServicePageAsync } from '../../../../pages/ServicePage';
import { SubscribePageAsync } from '../../../../pages/SubscribePage';
import { SupportPageAsync } from '../../../../pages/SupportPage';
import { UserRecordingAsync } from '../../../../pages/UserRecordingPage/UserRecording.async';
import {
  getMoreMenu,
  getRouteAnalytics,
  getRouteAuth,
  getRouteCabinets,
  getRouteCalendarCabinet,
  getRouteCalendarEmployee,
  getRouteCallDialog,
  getRouteCallProduct,
  getRouteCashRegister,
  getRouteClient,
  getRouteEditClient,
  getRouteEmployeesSummary,
  getRouteEmployeesTimetable,
  getRouteListCabinet,
  getRouteListEmployee,
  getRouteMessenger,
  getRouteMessengerChat,
  getRouteMessengerList,
  getRouteNewPassword,
  getRouteNotFound,
  getRouteOnlineRecording,
  getRoutePersonalArea,
  getRoutePortalRedirectPayment,
  getRouteProducts,
  getRouteProductCreate,
  getRouteProfile,
  getRouteBusinessSettings,
  getRouteRegister,
  getRouteRegisterConfirm,
  getRouteResetPassword,
  getRouteSales,
  getRouteServiceAdd,
  getRouteServiceCategory,
  getRouteServiceEdit,
  getRouteSettings,
  getRouteSupport,
  getRouteSussessPasword,
  getRouteUserRecording,
  getRouteUserRecordingAll,
  getRouteBusinessSettingsMain,
  getRouteBusinessSettingsCategory,
  getRouteBusinessSettingsLogo,
  getRouteBusinessSettingsOperatingMode,
  getRouteTelegramBotInfo,
  getRouteEmployeeCreate,
  getRouteSuccesSales,
  getRouteEmployeeEdit,
  getRouteCabinetsAdd,
  getRouteCabinetsEdit,
  getRouteImportClient,
  getRouteProfileLogo,
  getRoutePersonalAreaSettings,
  getRouteProfileSecurity,
  getRoutePaymentsAndSales,
  getRoutePaymentsSettings,
  getRouteCashRegisterCreate,
  getRouteCashRegisterEdit,
  getRouteTransactionsAll,
  getRouteSalesCashRegisters,
  getRouteNewSales,
  getRouteSubscribe,
  getRoutePortal,
  getRouteLicence,
  getRoutePrivacyPolicy,
  getRouteUserAgreement,
  getRouteSalesTransactions,
  getRouteToSite,
  getRouteCalendarSettings
} from '../../../../shared/const';
import { AppRoutes } from '../model/const';
import { AppRouteProps } from '../model/types';

/* тут мы храним все роуты приложения */
export const routeConfig: Record<AppRoutes, AppRouteProps> = {
  [AppRoutes.AUTH]: {
    path: getRouteAuth(),
    element: <AuthPageAsync />,
    authOnly: false,
    title: 'Авторизация'
  },
  [AppRoutes.SUPPORT]: {
    path: getRouteSupport(),
    element: <SupportPageAsync />,
    authOnly: true,
    title: 'Служба поддержки'
  },

  [AppRoutes.ONLINE_RECORDING]: {
    path: getRouteOnlineRecording(),
    element: <OnlineRecordingPageAsync />,
    authOnly: true,
    title: 'Онлайн-запись'
  },
  [AppRoutes.CLIENT]: {
    path: getRouteClient(),
    element: <ClientPageAsync />,
    authOnly: true,
    title: 'Клиенты'
  },
  [AppRoutes.CALL_PRODUCT]: {
    path: getRouteCallProduct(),
    element: <CallProductAsync />,
    authOnly: true,
    title: 'Вызов'
  },
  [AppRoutes.CALL_DIALOG_INIDLETIME]: {
    path: getRouteCallDialog(),
    element: <CallDialogInIdleTimeAsync />,
    authOnly: true,
    title: 'Вызов'
  },
  [AppRoutes.EMPLOYEES_SUMMARY]: {
    path: getRouteEmployeesSummary(),
    element: <EmployeersPageAsync />,
    authOnly: true,
    title: 'Сотрудники'
  },
  [AppRoutes.EMPLOYEES_TIMETABLE]: {
    path: getRouteEmployeesTimetable(),
    element: <EmployeeTimeTablePageAsync />,
    authOnly: true,
    title: 'График работы'
  },
  [AppRoutes.ANALYTICS]: {
    path: getRouteAnalytics(),
    element: <AnalyticsViewPageAsync />,
    authOnly: true,
    title: 'Аналитика'
  },
  [AppRoutes.PROTAL_REDIRECT_PAYMENT]: {
    path: getRoutePortalRedirectPayment(),
    element: <PortalRedirectPaymentPageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.NOT_FOUND]: {
    path: getRouteNotFound(),
    element: <NotFoundPageAsync />,
    authOnly: false,
    title: '404'
  },
  [AppRoutes.REGISTER]: {
    path: getRouteRegister(),
    element: <RegistrationPageAsync />,
    authOnly: false,
    title: 'Регистрация'
  },
  [AppRoutes.REGISTER_CONFIRM]: {
    path: getRouteRegisterConfirm(),
    element: <ConfirmRegistrationAsync />,
    authOnly: false,
    title: 'Регистрация'
  },
  [AppRoutes.RESET_PASSWORD]: {
    path: getRouteResetPassword(),
    element: <ResetPasswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.NEW_PASSWORD]: {
    path: getRouteNewPassword(),
    element: <NewPasswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.SUCCESS_PASSWORD]: {
    path: getRouteSussessPasword(),
    element: <SuccessPaswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.MESSENGER]: {
    path: getRouteMessenger(),
    element: <MessengerAsync />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.MESSENGER_MOBILE_CHAT]: {
    path: getRouteMessengerChat(),
    element: <MobileChat />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.MESSENGER_MOBILE_LIST]: {
    path: getRouteMessengerList(),
    element: <MessengerList />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.USERRECORDING]: {
    path: getRouteUserRecording(),
    element: <UserRecordingAsync />,
    authOnly: false,
    title: 'Запись'
  },
  [AppRoutes.USERRECORDINGALL]: {
    path: getRouteUserRecordingAll(),
    element: <UserRecordingAsync />,
    authOnly: false,
    title: 'Все записи'
  },
  [AppRoutes.MOREMENU]: {
    path: getMoreMenu(),
    element: <MorePageAsync />,
    authOnly: true,
    title: 'Меню'
  },
  // New Routes

  [AppRoutes.CABINETS]: {
    path: getRouteCabinets(),
    element: <CabinetsPageAsync />,
    authOnly: true,
    title: 'Ресурсы'
  },
  [AppRoutes.CABINETS_ADD]: {
    path: getRouteCabinetsAdd(),
    element: <CabinetsPageAddAsync />,
    authOnly: true,
    title: 'Добавление ресурса'
  },
  [AppRoutes.CABINETS_EDIT]: {
    path: getRouteCabinetsEdit(),
    element: <CabinetsPageAddAsync />,
    authOnly: true,
    title: 'Редактирование ресурса'
  },
  [AppRoutes.SERVICE_CATEGORY]: {
    path: getRouteServiceCategory(),
    element: <ServiceCategoriesPageAsync />,
    authOnly: true,
    title: 'Категории услуг / Услуги'
  },
  [AppRoutes.SERVICE_ADD]: {
    path: getRouteServiceAdd(),
    element: <ServicePageAsync />,
    authOnly: true,
    title: 'Добавление услуги'
  },
  [AppRoutes.SERVICE_EDIT]: {
    path: getRouteServiceEdit(),
    element: <ServicePageAsync />,
    authOnly: true,
    title: 'Редактирование услуги'
  },
  [AppRoutes.SETTINGS]: {
    path: getRouteSettings(),
    element: <SettingsPageAsync />,
    authOnly: true,
    title: 'Настройки'
  },
  [AppRoutes.SUBSCRIBE]: {
    path: getRouteSubscribe(),
    element: <SubscribePageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.PROFILE]: {
    path: getRouteProfile(),
    element: <ProfilePageAsync />,
    authOnly: true,
    title: 'Профиль'
  },
  [AppRoutes.PROFILE_LOGO]: {
    path: getRouteProfileLogo(),
    element: <ProfileLogoPageAsync />,
    authOnly: true,
    title: 'Изображения профиля'
  },
  [AppRoutes.PERSONAL_AREA]: {
    path: getRoutePersonalArea(),
    element: <PersonalAreaPageAsync />,
    authOnly: true,
    title: 'Личный кабинет'
  },
  [AppRoutes.BUSINESS_SETTINGS]: {
    path: getRouteBusinessSettings(),
    element: <BusinessSettingsPageAsync />,
    authOnly: true,
    title: 'Бизнес настройки'
  },
  [AppRoutes.BUSINESS_SETTINGS_MAIN]: {
    path: getRouteBusinessSettingsMain(),
    element: <BusinessSettingsMainAsync />,
    authOnly: true,
    title: 'Название и основная информация'
  },
  [AppRoutes.BUSINESS_SETTINGS_CATEGORY]: {
    path: getRouteBusinessSettingsCategory(),
    element: <BusinessSettingsCategoryAsync />,
    authOnly: true,
    title: 'Категория бизнеса'
  },
  [AppRoutes.BUSINESS_SETTINGS_LOGO]: {
    path: getRouteBusinessSettingsLogo(),
    element: <BusinessSettingsLogoAsync />,
    authOnly: true,
    title: 'Логотип и изображение профиля'
  },
  [AppRoutes.BUSINESS_SETTINGS_OPERATING_MODE]: {
    path: getRouteBusinessSettingsOperatingMode(),
    element: <BusinessSettingsOperatingModeAsync />,
    authOnly: true,
    title: 'Режим работы'
  },
  [AppRoutes.CASH_REGISTERS]: {
    path: getRouteCashRegister(),
    element: <CashRegisterAndBillsPageAsync />,
    authOnly: true,
    title: 'Кассы и счета'
  },
  [AppRoutes.SALES]: {
    path: getRouteSales(),
    element: <SalesPageAsync />,
    authOnly: true,
    title: 'Продажи'
  },
  [AppRoutes.PRODUCTS]: {
    path: getRouteProducts(),
    element: <ProductsPageAsync />,
    authOnly: true,
    title: 'Товары'
  },
  [AppRoutes.CALENDAR_EMPLOYEE]: {
    path: getRouteCalendarEmployee(),
    element: <ProductEmployeeAsync />,
    authOnly: true,
    title: 'Расписание'
  },
  [AppRoutes.CALENDAR_CABINET]: {
    path: getRouteCalendarCabinet(),
    element: <ProductCabinetAsync />,
    authOnly: true,
    title: 'Расписание'
  },
  [AppRoutes.LIST_EMPLOYEE]: {
    path: getRouteListEmployee(),
    element: <ProductEmployeeAsync />,
    authOnly: true,
    title: 'Расписание'
  },
  [AppRoutes.LIST_CABINET]: {
    path: getRouteListCabinet(),
    element: <ProductCabinetAsync />,
    authOnly: true,
    title: 'Расписание'
  },
  [AppRoutes.CLIENT_EDIT]: {
    path: getRouteEditClient(),
    element: <ClientEditPageAsync />,
    authOnly: true,
    title: 'Редактирование клиента'
  },
  [AppRoutes.CLIENT_ADD]: {
    path: getRouteEditClient(),
    element: <ClientEditPageAsync />,
    authOnly: true,
    title: 'Добавление клиента'
  },
  [AppRoutes.CLIENT_IMPORT]: {
    path: getRouteImportClient(),
    element: <ClientImportPageAsync />,
    authOnly: true,
    title: 'Импорт клиентов'
  },
  [AppRoutes.PRODUCT_ADD]: {
    path: getRouteProductCreate(),
    element: <CreateProductPageAsync />,
    authOnly: true,
    title: 'Добавление товара'
  },
  [AppRoutes.TELEGRAM_BOT_INFO]: {
    path: getRouteTelegramBotInfo(),
    element: <TelegramBotInfoPageAsync />,
    authOnly: true,
    title: 'Telegram Bot'
  },
  [AppRoutes.EMPLOYEE_CREATE]: {
    path: getRouteEmployeeCreate(),
    element: <EmployeesCreatePageAsync />,
    authOnly: true,
    title: 'Добавление сотрудника'
  },
  [AppRoutes.SUCCESS_SALE]: {
    path: getRouteSuccesSales(),
    element: <SuccesSalePageAsync />,
    authOnly: true,
    title: 'Оплата завершена!'
  },
  [AppRoutes.SALES_NEW]: {
    path: getRouteNewSales(),
    element: <SalesPageAsync />,
    authOnly: true,
    title: 'Новая продажа'
  },
  [AppRoutes.EMPLOYEE_EDIT]: {
    path: getRouteEmployeeEdit(),
    element: <EmployeesCreatePageAsync />,
    authOnly: true,
    title: 'Редактирование сотрудника'
  },
  [AppRoutes.PERSONAL_AREA_SETTINGS]: {
    path: getRoutePersonalAreaSettings(),
    element: <PersonalAreaSettingsPageAsync />,
    authOnly: true,
    title: 'Личный кабинет'
  },
  [AppRoutes.PROFILE_SECURITY]: {
    path: getRouteProfileSecurity(),
    element: <ProfileSecurityPageAsync />,
    authOnly: true,
    title: 'Безопасность'
  },
  [AppRoutes.PAYMENTS_AND_SALES]: {
    path: getRoutePaymentsAndSales(),
    element: <PaymentsAndSalesPageAsync />,
    authOnly: true,
    title: 'Платежи и продажи'
  },
  [AppRoutes.PAYMENTS_SETTINGS]: {
    path: getRoutePaymentsSettings(),
    element: <PaymentsSettingsPageAsync />,
    authOnly: true,
    title: 'Настройка платежей'
  },
  [AppRoutes.CASH_REGISTER_CREATE]: {
    path: getRouteCashRegisterCreate(),
    element: <CashRegisterCreateAsync />,
    authOnly: true,
    title: 'Новая касса'
  },
  [AppRoutes.CASH_REGISTER_EDIT]: {
    path: getRouteCashRegisterEdit(),
    element: <CashRegisterCreateAsync />,
    authOnly: true,
    title: 'Редактирование кассы'
  },
  [AppRoutes.TRANSACTIONS_ALL]: {
    path: getRouteTransactionsAll(),
    element: <TransactionsAllAsync />,
    authOnly: true,
    title: 'Все транзакции'
  },
  [AppRoutes.SALES_CASH_REGISTERS]: {
    path: getRouteSalesCashRegisters(),
    element: <SalesCashRegistersAndBillsAsync />,
    authOnly: true,
    title: 'Кассы и счета'
  },
  [AppRoutes.SALES_TRANSACTIONS]: {
    path: getRouteSalesTransactions(),
    element: <SalesTransactionsAsync />,
    authOnly: true,
    title: 'Финансовые операции'
  },
  [AppRoutes.PORTAL]: {
    path: getRoutePortal(),
    element: <PortalTelebonPageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.LICENCE]: {
    path: getRouteLicence(),
    element: <LicencePage />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.POLICY]: {
    path: getRoutePrivacyPolicy(),
    element: <PolicyPage />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.AGREEMENT]: {
    path: getRouteUserAgreement(),
    element: <AgreementPage />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.PERSONAL_SITE]: {
    path: getRouteToSite(),
    element: <PersonalSitePageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.CALENDAR_SETTINGS]: {
    path: getRouteCalendarSettings(),
    element: <CalendarSettingsPageAsync />,
    authOnly: true,
    title: 'Настройки календаря'
  }
};
